import React from "react"
import { SectionTitle } from "./SectionTitle"
import styled from "@emotion/styled"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { graphql, useStaticQuery } from "gatsby"
import { Testimonial } from "./Testimonial"
import { medias } from "../css/media"
import { OrangeExternalLink, OrangeLink } from "./buttons"

export const Testimonials = () => {
    const testimonialsData = useStaticQuery(graphql`
        query {
            allContentfulTestimonials {
                nodes {
                    title
                    image {
                        file {
                            url
                        }
                    }
                    childContentfulTestimonialsBodyRichTextNode {
                        json
                    }
                }
            }
        }
    `)

    return (
        <TestimonialsContainer>
            <SectionTitle title="TÉMOIGNAGES" />
            <Elements>
                {testimonialsData.allContentfulTestimonials.nodes.map((node, i) => (
                    <Testimonial key={`Testimonal-${i}`} title={node.title} body={documentToReactComponents(node.childContentfulTestimonialsBodyRichTextNode.json)} image={node.image} />
                ))}
            </Elements>
            <InviteContainer>
                <InviteText>Envie de discuter avec des bénévoles avant de vous lancer ? Rejoinez le slack de TEACHING BOOST</InviteText>
                <OrangeExternalLink href="https://join.slack.com/t/teachingboost/shared_invite/zt-i2shuuhe-w~hDFPXu2wSvdSx7mWij_Q" target="_blank">
                    C'est par ici !
                </OrangeExternalLink>
            </InviteContainer>
        </TestimonialsContainer>
    )
}

const TestimonialsContainer = styled.div`
    background-color: #dfe7ef;
    padding: 1rem;
    @media (${medias.tablet}) {
        padding: 1rem 4rem;
    }
`

const Elements = styled.div`
    display: flex;
    flex-direction: column;
    @media (${medias.tablet}) {
        flex-direction: row;
    }
`
const InviteContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`

const InviteText = styled.p`
    text-align: center;
`
