import React, { useMemo, useState } from "react"
import { AboutIcon, AssociationsIcon, VolunteersIcon } from "./HomeTabs.icons"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import styled from "@emotion/styled"
import { medias } from "../css/media"
import { graphql, Link, useStaticQuery } from "gatsby"
import { IoIosArrowRoundForward } from "react-icons/io"

export const HomeTabs = ({ association, volunteer, about }) => {
    //Teaching Boost logo
    const imageData = useStaticQuery(graphql`
        query {
            contentfulHpAbout {
                title
                logo {
                    file {
                        url
                    }
                }
            }
        }
    `)

    const config = useMemo(() => getConfig(about, volunteer, association), [about, volunteer, association])
    const [selectedKey, setSelectedKey] = useState(config[0].key)

    return (
        <HomeTabsRow>
            <Nav>
                {config.map((item) => (
                    <IconContainer key={item.key} onClick={() => setSelectedKey(item.key)} selected={selectedKey === item.key} color={item.color}>
                        <item.icon size="5em" color={item.color} />
                        {<IconTitle>{item.title}</IconTitle>}
                    </IconContainer>
                ))}
            </Nav>
            <ContentContainerWrapper>
                <Slider count={config.length} active={config.findIndex((item) => item.key === selectedKey)}>
                    {(config || []).map((current) => {
                        return (
                            <SliderItem>
                                <ContentContainer color={current.color}>
                                    {<current.icon size={"92%"} color={"#000"} />}
                                    <ContentBody color={current.color}>
                                        <ContentText>
                                            <ContentLogo src={imageData.contentfulHpAbout.logo.file.url} alt="imageData.contentfulHpAbout.logo.file.url" />
                                            <h2>{current.title}</h2>
                                            {documentToReactComponents(current.body.json)}
                                            <MoreInfoLink to={current.link || "/about"}>
                                                <MoreInfo>Plus d'informations</MoreInfo>
                                                <IoIosArrowRoundForward color="#f7c335" size="1.6rem" />
                                            </MoreInfoLink>
                                        </ContentText>
                                    </ContentBody>
                                </ContentContainer>
                            </SliderItem>
                        )
                    })}
                </Slider>
            </ContentContainerWrapper>
        </HomeTabsRow>
    )
}

const MoreInfo = styled.p`
    font-size: 0.8rem;
    margin: 0 auto;
`

const HomeTabsRow = styled.section`
    display: flex;
    flex-direction: column;

    @media (${medias.desktop}) {
        flex-direction: row;
    }
`

const Nav = styled.nav`
    font-size: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    @media (${medias.tablet}) {
        font-size: 1rem;
    }
    @media (${medias.desktop}) {
        flex-direction: column;
    }
`

const IconContainer = styled.a`
    cursor: pointer;
    text-decoration: none;
    width: 5em;
    padding: 1em 1.5em;
    margin: 0.7rem;
    color: ${(props) => props.color};
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-color: white;
    & > *,
    &:after,
    &:before {
        opacity: ${(props) => (props.selected ? "1" : "0.4")};
    }
    &:after,
    &:before {
        content: "";
        display: block;

        position: absolute;
        top: -0.1rem;
        right: -0.1rem;
        left: ${(props) => (props.selected ? "0" : "50%")};
        bottom: ${(props) => (props.selected ? "15%" : "80%")};

        border: 0.2em solid ${(props) => props.color};
        border-bottom: none;
        border-left: none;
        transition: all ease-in-out 150ms;
    }
    &:before {
        bottom: -0.1rem;
        left: -0.1rem;
        right: ${(props) => (props.selected ? "0" : "50%")};
        top: ${(props) => (props.selected ? "15%" : "80%")};

        border: 0.2em solid ${(props) => props.color};
        border-top: none;
        border-right: none;
    }

    &:hover {
        opacity: 1;
    }
`
const IconTitle = styled.div`
    margin-top: 0.4em;
    text-align: center;
    font-size: 1em;
    text-transform: uppercase;
    font-weight: bolder;
`

const ContentContainer = styled.article`
    background-color: ${(props) => props.color};
    position: relative;
    margin: 0.5rem 4.4rem 0.5rem 0.5rem;

    & > svg {
        position: absolute;
        opacity: 0.05;
        bottom: 0;
        left: 0;
        z-index: 10;
        width: 100% !important;
        height: auto !important;
        max-height: 100%;
        max-width: 100%;
    }
    @media (${medias.desktop}) {
        margin: 2rem 5.9rem 2rem 0;
        & > svg {
            width: auto !important;
            height: 92% !important;
            left: 3rem;
        }
    }
`
const ContentContainerWrapper = styled.section`
    display: flex;
    overflow: hidden;
`
const Slider = styled.div`
    display: flex;
    flex: 0 0 ${(props) => (props.count * 100).toFixed(0)}%;
    width: ${(props) => (props.count * 100).toFixed(0)}%;
    transform: translateX(-${(props) => ((props.active * 100) / props.count).toFixed(2)}%);
    transition: transform 150ms ease-in-out;
`
const SliderItem = styled.div`
    flex: 1 1 auto;
    display: flex;
`
const ContentBody = styled.div`
    background-color: white;
    position: relative;
    left: 3rem;
    height: calc(100% - 1.5rem);
    margin: 0.75rem 0;
    z-index: 5;
    min-height: 20rem;
    border: 0.2rem solid ${(props) => props.color};
    border-radius: 0.2rem;

    display: flex;

    @media (${medias.desktop}) {
        min-height: auto;
        left: 4rem;
        height: calc(100% - 4rem);
        margin: 2rem 0;
    }
`

const ContentLogo = styled.img`
    width: 30%;
    float: right;
    padding: 1rem;
    font-size: 1rem;
`

const MoreInfoLink = styled(Link)`
    //TODO: problem with z index maybe ? to bring the link to the front
    z-index: 11;
    cursor: pointer;
    height: 0.8rem;
    text-decoration: none;
    font-weight: bold;
    color: black;
    display: flex;
    float: right;
    align-items: center;
    margin-top: auto;
    padding-bottom: 0.4rem;
`

const ContentText = styled.div`
    padding-left: 1rem;
    margin: 0 auto;
    font-size: 0.8rem;
`

function getConfig(about, volunteer, association) {
    return [
        {
            key: "about",
            color: "#f7c335",
            icon: AboutIcon,
            ...about
        },
        {
            key: "volunteer",
            color: "#ff6c00",
            icon: VolunteersIcon,
            ...volunteer
        },
        {
            key: "association",
            color: "#3193d1",
            icon: AssociationsIcon,
            ...association
        }
    ]
}
