import React from "react"
import styled from "@emotion/styled"
import { medias } from "../css/media"

export const Testimonial = ({ title, body, image }) => {
    return (
        <TestimonialContainer>
            <Picture src={image.file.url} alt="profile-pic" />
            <TestimonialTitle> {title}</TestimonialTitle>
            <TestimonialBody> {body}</TestimonialBody>
        </TestimonialContainer>
    )
}

//Todo: review the size of each container & the distances between each one of them
const TestimonialContainer = styled.div`
    flex: 1 1 33%;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: white;
    padding: 1.68rem;
    border-radius: 3.5px;
    border-bottom: 1.125rem solid #f7c335;
    margin-bottom: 1rem;
    @media (${medias.tablet}) {
        margin-right: 2.84rem;
    }
`

const Picture = styled.img`
    height: 3.75rem;
    width: 3.75rem;
    border-radius: 50%;
`

const TestimonialTitle = styled.p`
    font-size: 0.68rem;
    font-weight: bold;
`

const TestimonialBody = styled.p`
    font-size: 0.6rem;
`
