import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import { colors } from "../css/colors"
import { medias } from "../css/media"
import { HomeTabs } from "../components/HomeTabs"
import { Testimonials } from "../components/Testimonials"
import { SearchBar, UrgentCart, useUrgentMissions } from "../app/Missions/MissionSearch"
import { SectionTitle } from "../components/SectionTitle"
import { OrangeLink } from "../components/buttons"
import { Partners } from "../components/Partners"

export default function Home({
    data: {
        contentfulHpWelcome: {
            slogan,
            background: {
                file: {
                    url: backgroundUrl,
                    details: {
                        image: { height: backgroundHeight, width: backgroundWidth }
                    }
                }
            }
        },
        contentfulHpAbout,
        contentfulHpVolunteerPresentation,
        contentfulHpAssociationsPresentation
    }
}) {
    const urgentMissions = useUrgentMissions()
    const backgroundRatio = backgroundHeight / backgroundWidth
    return (
        <Layout>
            <SEO title="Accueil" />
            <WelcomeContainer background={backgroundUrl} ratio={backgroundRatio}>
                <Slogan>
                    {slogan} <br />
                    <br />
                    <SearchBar placeholder="Trouvez une mission près de chez vous ..." />
                </Slogan>
            </WelcomeContainer>

            <HomeTabs about={contentfulHpAbout} association={contentfulHpAssociationsPresentation} volunteer={contentfulHpVolunteerPresentation} />
            {urgentMissions.length ? (
                <UrgentRow>
                    <SectionTitle>Missions Urgentes</SectionTitle>

                    <MissionRow>
                        {urgentMissions.map((mission, i) => (
                            <UrgentCart key={mission.id || i} mission={mission} />
                        ))}
                    </MissionRow>
                    <Center>
                        <OrangeLink to="/app/search">Rechercher une mission</OrangeLink>
                    </Center>
                </UrgentRow>
            ) : null}
            <Testimonials />
            <Partners />
        </Layout>
    )
}

export const query = graphql`
    query {
        contentfulHpWelcome {
            background {
                file {
                    url
                    details {
                        image {
                            height
                            width
                        }
                    }
                }
            }
            slogan
        }
        contentfulHpAbout {
            logo {
                file {
                    url
                }
            }
            title
            link
            body {
                json
            }
        }
        contentfulHpAssociationsPresentation {
            logo {
                file {
                    url
                }
            }
            title
            link
            body {
                json
            }
        }
        contentfulHpVolunteerPresentation {
            logo {
                file {
                    url
                }
            }
            title
            link
            body {
                json
            }
        }
    }
`

const WelcomeContainer = styled.div`
    background-image: linear-gradient(0deg, rgba(74, 120, 181, 0.6) 0%, rgba(74, 120, 181, 0.6) 100%), url(${(props) => props.background});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 80vh;
    padding-top: 3rem;

    display: flex;

    @media (${medias.tablet}) {
        width: 100%;
    }
`

const Slogan = styled.p`
    margin: auto;
    font-size: 1.5rem;
    max-width: 85%;
    color: ${colors.lightText};
    text-align: center;

    @media (${medias.tablet}) {
        font-size: 2rem;
        max-width: 66%;
    }
`

const UrgentRow = styled.section`
    background-color: #5294c1;
    padding: 1rem;
    margin: 0.5rem 0 0;
    @media (${medias.tablet}) {
        padding: 1rem 4rem;
    }
`
const MissionRow = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (${medias.tablet}) {
        flex-direction: row;

        & > * {
            flex: 1 1 33%;
            max-width: 33%;
        }
    }
`
const Center = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
