import React, { useEffect, useState } from "react"
import { isBack } from "../isBack"
import styled from "@emotion/styled"
import { medias } from "../css/media"
import { firestore, queryResultToArray } from "../firebase"
import { SectionTitle } from "./SectionTitle"

export const Partners = () => {
    const [partners, setPartners] = useState([])
    useEffect(() => {
        if (isBack) return null
        firestore
            .collection("partners")
            .get()
            .then((snap) => {
                setPartners(queryResultToArray(snap))
            })
    }, [])

    return (
        <Container>
            <SectionTitle title="Partenaires" />
            <Row>
                {partners.map((partner) => (
                    <Partner src={partner.logo?.src} alt={partner.fullName} />
                ))}
            </Row>
        </Container>
    )
}

const Container = styled.section`
    padding: 1rem;
    @media (${medias.tablet}) {
        padding: 1rem 4rem;
    }
`
const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    padding: 1rem 0;
`
const Partner = styled.img`
    flex: 0 0 auto;
    height: 2.5rem;
    margin: auto;
`
